import React from 'react';
import { NavLink } from 'react-router-dom';
import InsightsIcon from '@mui/icons-material/Insights';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExtensionSharpIcon from '@mui/icons-material/ExtensionSharp';
import StarsIcon from '@mui/icons-material/Stars';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import headerimg1 from '../../assets/CH-image.jpg';
import headerimg2 from '../../assets/sky-hills-image01.jpg';
import gurrente1 from '../../assets/Badge100.png';

import gurrente2 from '../../assets/sky-excellence-of-15-years.png';
import herosecimg from '../../assets/Park-View-City-Cinema01-1.jpg';
import homeimg from '../../assets/house_PNG50.png';
import sketch from '../../assets/Sketch-underline-2.png';
import curveimg from '../../assets/download.svg';
import userimg from '../../assets/user.png';
import stars from '../../assets/Stars.png';
import ProjectPage from '../project/ProjectPage';
import './Home.css';
import quote from '../../assets/Quote.svg';
import ContactPage from '../contact/ContactPage';

export default function Home() {
  return (
    <>
      <div className="background-container">
        <div className="background-opacity" />
        <div className="header_heading">
          <h1>MOVE ON UP</h1>
          <p>We have more than 18 years of experience</p>
          <div className="header_imgs_main_div">
            <div className="header_img_1">
              <img src={headerimg1} alt="" />
              <p>CAPTIAL HILLS</p>
            </div>
            <div className="header_img_1">
              <img src={headerimg2} alt="" />
              <p>SKY HILLS</p>
            </div>
          </div>
        </div>
      </div>
      <div className="title_main_div">
        <div className="title_sub_div">
          <div className="title_div">
            <h2>
              Welcome to
              <br />
              MNA International Group
            </h2>
          </div>
          <div className="gurrente_imgs_div">
            <div className="gurrente_img_1">
              <img src={gurrente1} alt="." />
            </div>
            <div className="gurrente_img_1">
              <img src={gurrente2} alt="." />
            </div>
          </div>
        </div>
      </div>
      <div className="hero_section_main_div">
        <div className="hero_section_sub_div">
          <div className="hero_sectio_content_div">
            <div className="hero_section_para">
              <p>
                Welcome to the rapidly emerging Real Estate Company
                <span className="mna_p_span"> MNA International Group </span> Islamabad working from
                last 18 years. Our professional team is serving many housing societies for marketing
                purposes and has completed many renowned projects achieving high excellency. Use of
                most modern techniques of marketing like e-media and web-based medium for mass
                communication is one of our strengths to reach-out maximum number of clients and
                customers.
              </p>
            </div>
            <div className="herosecimg_div">
              <img src={herosecimg} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="hero_section_bottom_div">
        <div className="hero_section_bottom_sub_div">
          <div className="background-image_2">
            <div className="background-opacity_2" />

            <div className="bottom_content">
              <div className="bottom_content_img">
                <img src={homeimg} alt="" />
              </div>
            </div>
            <div className="bottom_content_text">
              <h1>WE ARE 24/7 AVAILABLE</h1>
              <div className="text-btm_img">
                <img src={sketch} alt="" />
              </div>
              <div className="btn_div">
                <NavLink to="/contact" className="contact_btn_homepage" activeClassName="active">
                  CONTACT US
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* project test */}
      <div className="projects_main_div">
        <img src={curveimg} alt="" className="Vimg" />
      </div>
      <ProjectPage projectScreen />
      <div className="choose_us_main_div">
        <div className="background-opacity_4" />
        <div className="choose_bg_div">
          <div className="choose_sub_div">
            <div className="choose_heading">
              <h1>WHY CHOOSE MNA INTERNATIONAL GROUP</h1>
              <div className="choose_btm_line" />
            </div>
            <div className="choose_opptions_main_div">
              <div className="choose_opption_left_div">
                <div className="opptions_main_div_left">
                  <div className="opption_1">
                    <div className="opptions_icons">
                      <InsightsIcon style={{ color: 'orange', fontSize: '1.5em' }} />
                    </div>

                    <h1>MARKETERS</h1>
                  </div>
                  <div className="opption_1">
                    <div className="opptions_icons">
                      <AttachMoneyIcon style={{ color: 'orange', fontSize: '1.5em' }} />
                    </div>

                    <h1>INVESTMENT CONSULATANT</h1>
                  </div>
                  <div className="opption_1">
                    <div className="opptions_icons">
                      <ExtensionSharpIcon style={{ color: 'orange', fontSize: '1.5em' }} />
                    </div>

                    <h1>DLEALER & PROMOTERS</h1>
                  </div>
                  <div className="opption_1">
                    <div className="opptions_icons">
                      <StarsIcon style={{ color: 'orange', fontSize: '1.5em' }} />
                    </div>

                    <h1>DEVELOPERS</h1>
                  </div>
                  <div className="opption_1">
                    <div className="opptions_icons">
                      <DomainOutlinedIcon style={{ color: 'orange', fontSize: '1.5em' }} />
                    </div>
                    <h1>PROPERTY ADVISOR</h1>
                  </div>
                </div>
              </div>
              {/* reviews */}
              <div className="reviews_main_div">
                <div className="review_1">
                  <div className="quote_img_main_div">
                    <img src={quote} alt="" />
                  </div>
                  <div className="user_img_div">
                    <img src={userimg} alt="" />
                  </div>
                  <div className="review_para">
                    <p>
                      Had taken consultation regarding purchase of property in islamabad for my
                      relatives, thanks to team @MNA Group for making it that simple. Highly
                      recommended..!
                    </p>
                    <h4>Abubakar Rehman</h4>
                  </div>
                </div>
                <div className="review_1">
                  <div className="quote_img_main_div">
                    <img src={quote} alt="" />
                  </div>
                  <div className="user_img_div">
                    <img src={userimg} alt="" />
                  </div>
                  <div className="review_para">
                    <p>
                      Its a best & reliable place to get your investment secure through MNA Group.
                      Highly Recommended
                    </p>
                    <h4>Atiya Sher</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="clints_rating_div">
              <h1>Our clients love us</h1>
              <h4>We have an average of 9.6</h4>
              <div className="rating_img_div">
                <img src={stars} alt="" />
              </div>
              <button type="button">READ ALL REVIEWS</button>
            </div>
          </div>
        </div>
      </div>
      <div className="contact_us_home_page">
        {/* <div className="Contact_background">
          <div className="background-opacity_5" />
          <div className="contact_home_page_inner">
            <div className="contact_home_heading_main_div">
              <div className="contact_home_heading_sub_div">
                <h1>WE ARE READY TO SERVE YOU IN REAL ESTATE</h1>
                <div className="contact_btm_line" />
                <h2>CALL US TODAY</h2>
                <p>+92 300-006-9732</p>
              </div>
            </div>
            <div className="contact_home_from">
              <div className="form_here_home_page">
                <h1>
                  Request for <span>DISCOUNT</span> Booking
                </h1>
                <div className="forms_fields_home_page">
                  <input type="text" placeholder="Full Name" />
                  <div className="input_btm_line" />

                  <input type="number" placeholder="Contact Number" />
                  <div className="input_btm_line" />
                  <textarea type="text-area" placeholder="Type a message..." rows={10} />
                  <div className="input_btm_line" />
                  <button type="button">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <ContactPage />
      </div>
    </>
  );
}
