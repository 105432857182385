/* eslint-disable import/prefer-default-export */
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import NewsImage from '../assets/images/News-1.jpg';
import NewsImage2 from '../assets/images/News-2.jpg';
import NewsImage3 from '../assets/images/News-3.jpg';

import ProjectImage from '../assets/images/Project-1.jpg';
import ProjectImage3 from '../assets/images/Project-3.jpg';
import ProjectImage4 from '../assets/images/Project-4.jpg';
import ProjectImage2 from '../assets/images/Project-2.jpg';

export const newsData = [
  {
    id: 1,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage,
  },
  {
    id: 2,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage2,
  },
  {
    id: 3,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 4,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  {
    id: 5,
    Date: 'October 7, 2023 / Latest News',
    headline: 'Sky Hills: Elevating Every Aspect of Your Lifestyle',
    description:
      'Islamabad: If you are looking for a perfect living place, then look nowhere, as Sky Hills Islamabad is your true...',
    cover: NewsImage3,
  },
  // Add more news items as needed
];
export const addresses = [
  {
    address: '50 Block C Broadway Commercial DHA Phase 8, Lahore, Pakistan',
    // eslint-disable-next-line react/jsx-filename-extension
    icon: <LocationOnRoundedIcon style={{ color: 'orange', fontSize: '1.5em', width: '8%' }} />,
  },
  {
    icon: <LocalPhoneIcon style={{ color: 'orange', fontSize: '1.5em', width: '8%' }} />,
    number: '+92300 0069732',
  },
  {
    icon: <EmailIcon style={{ color: 'orange', fontSize: '1.5em', width: '8%' }} />,
    email: 'mnainternationalgroup@gmail.com',
  },
];
export const projects = [
  { image: ProjectImage, name: 'CAPITAL SMART CITY' },
  { image: ProjectImage2, name: 'AL NOOR ORCHARD' },
  { image: ProjectImage3, name: 'PARK VIEW CITY' },
  { image: ProjectImage4, name: 'BAHRIA TOWN KARACHI ' },
  { image: ProjectImage, name: 'LAHORE SMART CITY' },
  { image: ProjectImage4, name: 'NEW CITY PARADISE' },
];
