/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import Pagination from '@mui/material/Pagination';
import API from '../../api/index';
import { mediaUrl } from '../../config';
import './Video.css';

function Video() {
  const itemsPerPage = 9;
  const [totalVideos, setTotalVideos] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedVideos, setDisplayedVideos] = useState([]);

  const updateDisplayedVideos = (videosArray) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const videosToDisplay = videosArray.slice(startIndex, endIndex);
    setDisplayedVideos(videosToDisplay);
  };

  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const videoTopRef = useRef(null);
  const handleSetActiveVideo = (index) => {
    setActiveVideoIndex(index);

    if (videoTopRef.current) {
      videoTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleGetAllVideos = () => {
    API('get', '/videos/')
      .then((res) => {
        setTotalVideos(res.data.videos.length);
        updateDisplayedVideos(res.data.videos);
      })
      .catch((err) => {
        console.log('Error fetching videos', err);
      });
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  useEffect(() => {
    handleGetAllVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const isPlaying = false;

  return (
    <div className="main_videos_div">
      <div className="video-top" ref={videoTopRef}>
        <ReactPlayer
          playing
          url={`${mediaUrl}/${displayedVideos[activeVideoIndex]?.video}`}
          controls
          className="react_player"
        />
      </div>
      <div className="choose__video">
        {displayedVideos?.map((item, index) => {
          return (
            <div
              key={item}
              className="video-container"
              style={{
                position: 'relative',
              }}
            >
              <ReactPlayer
                url={
                  item.video
                    ? `${mediaUrl}/${item.video}`
                    : 'https://www.youtube.com/watch?v=7EHnQ0VM4KY'
                }
                width="250"
                playing={isPlaying}
                className="play-videos"
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                }}
              />
              <p>{item.title}</p>
              <div
                style={{
                  position: 'absolute',
                  zIndex: '123456789',
                  height: '100%',
                  width: '100%',
                  top: '0',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleSetActiveVideo(index);
                }}
              />
            </div>
          );
        })}
      </div>
      <Pagination
        count={Math.ceil(totalVideos / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
}
export default Video;
