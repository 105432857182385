import React, { useState, useEffect } from 'react';
import API from '../../api/index';
import './project.css';
import { mediaUrl } from '../../config';

import DumyImageProject from '../../assets/New-City-Paradise.jpg';

function Project(props) {
  const { projectScreen } = props;
  const [projects, setProjects] = useState([]);
  console.log('projects', projects);

  const handleGetAllProjects = () => {
    API('get', '/projects/')
      .then((res) => {
        setProjects(res.data.news);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetAllProjects();
  }, []);

  return (
    <section className="container projectsbg">
      <div className={projectScreen ? 'main-section' : 'main-section1'}>
        <h1>OUR PROJECTS</h1>
        <div className="projects_list">
          {projects?.map((item) => (
            <div className="header_img_1_projects">
              {item?.photo ? (
                <img src={`${mediaUrl}/${item.photo}`} alt="" />
              ) : (
                <img src={DumyImageProject} alt="Dummy" />
              )}
              <button type="button">{item.title}</button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Project;
