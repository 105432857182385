/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import './Contact.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import Contactus from '../../assets/Contact-Us.jpg';
import { addresses } from '../../utils/data';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    number: '',
  });

  const sendEmail = (e) => {
    e.preventDefault();
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const numberRegex = /^\d+$/;
    if (formData?.name?.length < 3) {
      toast.error('name must be more then 3 ');
    } else if (formData?.email && !emailRegex.test(formData.email)) {
      toast.error('incorrect format abc@xyz.com ');
    } else if (formData?.number.length < 9) {
      toast.error('Number must be more then 10 Digits');
    } else if (formData?.number && !numberRegex.test(formData.number)) {
      toast.error('Invalid phone');
    } else {
      emailjs
        .sendForm('service_3agchuf', 'template_fnnuufm', e.target, 'He2OLlgB-PA0i1HUJ')
        .then((result) => {
          console.log('Email sent successfully:', result.text);
          toast.success('Email sent successfully');
        })
        .catch((error) => {
          console.error('Error sending email:', error);
        });

      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        number: '',
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="main-section_Contact">
      <div className="main_section_sub_div">
        <div className="head-office">
          <div className="head-content">
            <h1>HEAD OFFICE</h1>
            <div className="bottom_line_contact" />
            <div>
              {addresses.map((data, index) => (
                <div key={index} className="head_address">
                  {data.icon}
                  <p>{data.address}</p>
                  <p>{data.number}</p>
                  <p>{data.email}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="Contact-Us">
            <img src={Contactus} alt="" />
          </div>
        </div>
        <div className="contact-container">
          <h2>Get in touch</h2>
          <form onSubmit={sendEmail}>
            <div className="Section">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />

              <input
                type="email"
                id="email"
                placeholder="Abubakar@gmail.com"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="Section">
              <input
                type="text"
                id="Subject"
                placeholder="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
              <input
                type="number"
                id="contact number"
                placeholder="Contact Number"
                name="number"
                value={formData?.number}
                onChange={handleChange}
                required
              />
            </div>

            <textarea
              id="message"
              className="form_textarea"
              name="message"
              placeholder="Type a Message..."
              value={formData.message}
              onChange={handleChange}
              required
              rows={10}
            />

            <button type="submit" className="form_btn">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
