/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Init
import React, { useState } from 'react';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Whatsapp from './components/Common/whatsapp/Whatsapp';
import Navbar from './components/Common/navbar/Navbar';
import Footer from './components/Common/footer/Footer';
import openbtn from './assets/open-button.png';

import closebtn from './assets/close-button.png';
// import callnow from './assets/call-now.png';

// Files
import Index from './routes/index';
import './css/index.css';
// Component

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="App">
      <a href="https://wa.me/+923000069732" target="_blank" rel="noreferrer">
        <div className="call_now_img">{/* <img src={callnow} alt="" /> */}</div>
      </a>

      <div className="scrolling_main_div_btn">
        <button type="button" onClick={toggleVisibility}>
          {isVisible ? (
            <div className="disclaimer_btn">
              <img src={closebtn} alt="" />
            </div>
          ) : (
            <div className="disclaimer_btn">
              <img src={openbtn} alt="" />
            </div>
          )}
        </button>
        {isVisible && (
          <div className="scrolling_main_div">
            <div className="scrolling-text">
              Disclaimer: It is conveyed to all respected customers to only transfer your payments
              to the official account of Mna International Group, or we will not be responsible for
              any kind of losses or damages..
            </div>
          </div>
        )}
      </div>

      <Navbar />
      <Whatsapp />
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar transition={Flip} />

      {/* Routes */}
      <Index />
      <Footer />
    </div>
  );
}

// Export
export default App;
