import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { NavLink, Link } from 'react-router-dom';
import tiktok from '../../../assets/tiktok-256.png';
import './Footer.css';

function Footer() {
  const iconStyle = {
    fontSize: '35px',
    color: 'white',
  };
  return (
    <div className="footer_main_div">
      <div className="footer_subdiv">
        <div className="footer_content">
          <div className="footer_row_content">
            <h1>HEAD OFFICE</h1>
            <div className="bottom_line" />
          </div>
          <div className="footer_address">
            <LocationOnIcon />
            <p>50 Block C Broadway Commercial Dha Phase 8, Lahore, Pakistan</p>
          </div>
          <div className="footer_address">
            <LocalPhoneIcon />
            <p>0300 0069732</p>
          </div>

          <div className="footer_address">
            <EmailIcon />
            <p>mnainternationalgroup@gmail.com</p>
          </div>

          <div className="social_icons">
            <NavLink to="https://www.facebook.com/MNAinternationalgroup?mibextid=LQQJ4d">
              <FacebookIcon style={iconStyle} />
            </NavLink>
            <NavLink to="https://www.instagram.com/mnainternationalgroup?igshid=YzAwZjE1ZTI0Zg%3D%3D&utm_source=qr">
              <InstagramIcon style={iconStyle} />
            </NavLink>
            <NavLink to="https://youtube.com/@mnainternationalgroup?si=VB4LqLUoqrEjo_S3">
              <YouTubeIcon style={iconStyle} />
            </NavLink>
            <a href="https://www.tiktok.com/@mnainternationalgroup" rel="noopener noreferrer">
              <img src={tiktok} alt="." className="tiktok_icon" />
            </a>
          </div>
          <div className="footer_row_content">
            <h1>COMPLAINT</h1>
            <div className="bottom_line" />
          </div>
          <div className="footer_address">
            <EmailIcon />
            <p>mnainternationalgroup@gmail.com</p>
          </div>
          <div className="footer_address">
            <LocalPhoneIcon />
            <p>0300 0069732</p>
          </div>
        </div>
        <div className="footer_content">
          <div className="footer_row_content">
            <h1>QUICK LINKS</h1>
            <div className="bottom_line" />
          </div>
          <div className="footer_ancor">
            <nav>
              <ul>
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/videos">VIDEOS</Link>
                </li>
                <li>
                  <Link to="/projects">PROJECTS</Link>
                </li>
                <li>
                  <Link to="/news">NEWS</Link>
                </li>
                <li>
                  <Link to="/contact">CONTACT</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footer_row_content">
            <h1>DISCLAIMER</h1>
            <div className="bottom_line" />
          </div>
          <div className="footer_address_disclamer ">
            <p>
              All data, stats, and information set forth on this platform/website/blogs are
              initially gathered from reliable online sources. However, no warranties/guarantees are
              made for the precision/accuracy of the information shared in any content on the
              website. Any investment-related financial or physical loss inflicted on the user or
              faced by the user or any of his/her associates using the MNA Marketing website/blogs
              could not make MNA Marketing held responsible in any local or international justice
              institutions/courts.Please proceed with the investments at your own risk.
            </p>
          </div>
        </div>
        <div className="footer_content">
          <div className="footer_row_content">
            <h1>PROJECTS</h1>
            <div className="bottom_line" />
            <div className="projects-main_div">
              <div className="projects_name">
                <p>Lahore Smart City</p>
                <p>ICHS</p>
                <p>Capital Smart City</p>
                <p>Royal Orchard Multan</p>
                <p>DHA Lahore</p>
                <p>Park View City Lahore</p>
              </div>
              <div className="projects_name project_name_res">
                <p>Al Noor Orchard Lahore</p>
                <p>Bahria Orchad Lahore</p>
                <p>Bahria Town Lahore</p>
                <p>Park View City Islamabad</p>
                <p>Al Rehman Garden Phase 7 Lahore</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
