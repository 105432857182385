let baseUrl = 'http://localhost:5000/api/v1';
let mediaUrl = 'http://localhost:5000';

if (window.location.host === 'mnainternationalgroup-dev.falconweb.app') {
  baseUrl = 'https://mnainternationalgroup-api-dev.falconweb.app/api/v1';
  mediaUrl = 'https://mnainternationalgroup-api-dev.falconweb.app';
} else if (window.location.host === 'reactapp-qa.falconweb.app') {
  baseUrl = 'https://reactapp-api-qa.falconweb.app/api';
  mediaUrl = 'https://reactapp-api-qa.falconweb.app';
} else if (window.location.host === 'reactapp-staging.falconweb.app') {
  baseUrl = 'https://reactapp-api-staging.falconweb.app/api';
  mediaUrl = 'https://reactapp-api-staging.falconweb.app';
} else if (window.location.host === 'admin.mnainternationalgroup.com') {
  baseUrl = 'https://api.mnainternationalgroup.com/api/v1';
  mediaUrl = 'https://api.mnainternationalgroup.com';
}

module.exports = { baseUrl, mediaUrl };
