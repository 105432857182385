import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import navbarlogo from '../../../assets/Logo.png';

import './Navbar.css';

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="navbar_main_div">
      <div className="navbar_subdiv">
        <div className="navbar_logo_div">
          <NavLink to="/" className={location.pathname === '/' ? 'active' : 'active-link'}>
            <img src={navbarlogo} alt="logo" />
          </NavLink>
        </div>

        <div className="menu_mobile">
          <MenuIcon onClick={toggleMenu} />
        </div>
        {showMenu && (
          <div className="topnav_mobile">
            <NavLink to="/" className={location.pathname === '/' ? 'active' : 'active-link'}>
              HOME
            </NavLink>
            <NavLink
              exact
              to="/videos"
              className={location.pathname === '/videos' ? 'active' : 'active-link'}
            >
              VIDEOS
            </NavLink>
            <NavLink
              to="/projects"
              className={location.pathname === '/projects' ? 'active' : 'active-link'}
            >
              PROJECTS
            </NavLink>
            <NavLink
              to="/news"
              className={location.pathname === '/news' ? 'active' : 'active-link'}
            >
              NEWS
            </NavLink>
            <NavLink
              to="/contact"
              className={location.pathname === '/contact' ? 'active' : 'active-link'}
            >
              CONTACT
            </NavLink>
          </div>
        )}

        <div className="topnav">
          <NavLink exact to="/" className={location.pathname === '/' ? 'active' : 'active-link'}>
            HOME
          </NavLink>
          <NavLink
            exact
            to="/videos"
            className={location.pathname === '/videos' ? 'active' : 'active-link'}
          >
            VIDEOS
          </NavLink>
          <NavLink
            to="/projects"
            className={location.pathname === '/projects' ? 'active' : 'active-link'}
          >
            PROJECTS
          </NavLink>
          <NavLink to="/news" className={location.pathname === '/news' ? 'active' : 'active-link'}>
            NEWS
          </NavLink>
          <NavLink
            to="/contact"
            className={location.pathname === '/contact' ? 'active' : 'active-link'}
          >
            CONTACT
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
