// Init
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Routes
import Home from '../containers/home/HomePage';
import VideoPage from '../containers/video/VideoPage';
import NewsPage from '../containers/news/NewsPage';
import ProjectPage from '../containers/project/ProjectPage';
import ContactPage from '../containers/contact/ContactPage';

function Index() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/videos" element={<VideoPage />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/projects" element={<ProjectPage />} />
      <Route path="/contact" element={<ContactPage />} />
    </Routes>
  );
}
export default Index;
