import React, { useState } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import './Whatsapp.css';

function Whatsapp() {
  const [openChat, setOpenChat] = useState(false);

  return (
    <>
      <div className="button-container">
        <button className="whatsapp-icon-div" type="button" onClick={() => setOpenChat(!openChat)}>
          {openChat ? (
            <CloseIcon fontSize="large" sx={{ color: 'white' }} />
          ) : (
            <WhatsAppIcon fontSize="large" sx={{ color: 'white' }} />
          )}
        </button>
      </div>
      {openChat ? (
        <div className="chat-box">
          <a
            href="https://wa.me/+923000069732"
            target="_blank"
            rel="noreferrer"
            className="chat-link"
          >
            <WhatsAppIcon fontSize="large" sx={{ color: 'green' }} />
            MNA International Group
            <WhatsAppIcon fontSize="small" sx={{ color: 'green' }} />
          </a>
        </div>
      ) : (
        <div className="chat-box-text">Chat via WhatsApp</div>
      )}
    </>
  );
}

export default Whatsapp;
