import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './News.css';
import Pagination from '@mui/material/Pagination';
import DumyImage from '../../assets/Park-View-City.jpg';

import API from '../../api/index';
import { mediaUrl } from '../../config';

function News() {
  const itemsPerPage = 6;
  const [totalNews, setTotalNews] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayNews, setDisplayNews] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  console.log('displayNews', displayNews);
  const updateDisplayedNews = (newsArray) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newsToDisplay = newsArray.slice(startIndex, endIndex);
    setDisplayNews(newsToDisplay);
  };

  const handleGetAllNews = () => {
    API('get', '/news/')
      .then((res) => {
        const filteredNews = res.data.news.filter((item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase()),
        );

        setTotalNews(filteredNews.length);
        updateDisplayedNews(filteredNews);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = () => {
    handleGetAllNews();
  };

  useEffect(() => {
    handleGetAllNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchQuery]);

  return (
    <div>
      <section className="container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button type="button" onClick={handleSearch}>
            Search
          </button>
        </div>
        <div className="News">
          {displayNews.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="box" key={index}>
              {item.photo ? (
                <img src={`${mediaUrl}/${item.photo}`} alt="" />
              ) : (
                <img src={DumyImage} alt="Dummy" />
              )}{' '}
              <p>
                <p className="date-p">{moment(item.createdAt).format('DD/MM/YYYY')}</p>
                <h2 className="headline-h2">{item.title}</h2>
                <h3 className="details-h3">{item.details}</h3>
              </p>
            </div>
          ))}
        </div>
        <Pagination
          count={Math.ceil(totalNews / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </section>
    </div>
  );
}

export default News;
